<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">主页</div>
        </v-app-bar>
        <v-main>
            <v-container class="full-height">
                <v-row class="full-height">
                    <v-col class="img-col">
                        <v-img
                            :src="require('../assets/decoration-image.png')" 
                            max-height="300"
                            contain
                        />
                    </v-col>
                    <v-col class="menu-col">
                        <v-card
                            width="300"
                        >
                            <v-list>
                                <v-subheader>工具箱目录</v-subheader>
                                <v-divider />
                                <v-list-item @click="$router.push('/name')">
                                    <v-list-item-icon><v-icon>mdi-cube-outline</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>姓名对比</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="redirect('grade.html')">
                                    <v-list-item-icon><v-icon>mdi-cube-outline</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>成绩分布分析</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
export default {
    methods: {
        redirect (path) {
            window.location.pathname = path
        }
    }
}
</script>
<style scoped>
.full-height {
    height: 100%;
}
.img-col, .menu-col {
    position: relative;
}

.img-col > *, .menu-col > * {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .img-col {
    background-color: red;
}

.menu-col {
    background-color: blue;
} */
</style>